@font-face {
	font-family: "718-book";
	src: url("/static/font/718/sevenoneeight-book.woff2") format("woff2"),
		url("/static/font/718/sevenoneeight-book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "718-bold";
	src: url("/static/font/718/sevenoneeight-bold.woff2") format("woff2"),
		url("/static/font/718/sevenoneeight-bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "VPSansNext-Regular";
	src: url("/static/font/VPSansNext/VPSansNext-Regular.woff2") format("woff2"),
		url("/static/font/VPSansNext/VPSansNext-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
